.Consultas {
  margin-top: 20px;
}

.categoria {
  margin-bottom: 20px;
}

.separator {
  border-color: #ddd;
}

.solicitud,
.consulta {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.solicitud .card-body,
.consulta .card-body {
  padding: 20px;
}

.solicitud .card-title,
.consulta .card-title {
  margin-bottom: 15px;
  font-size: 24px;
}

.solicitud .card-text,
.consulta .card-text {
  margin-bottom: 10px;
}