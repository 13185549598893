.burbujaChat {
    background-color: #fddded;
    margin: 4px 0;
    padding: 8px;
    border-radius: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .burbujaChat h3, .burbujaChat p {
    margin: 2px 0;
    font-size: 0.9rem;
  }
  
  .fechaMensaje {
    font-size: 0.8em;
    color: gray;
    text-align: right;
  }

  .listaChats {
    display: flex;
    flex-direction: column;
    max-height: 600px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px;
  }
  
  .iconoPerfil {
    width: 100%;
    height: auto;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
  }

  .imagenPerfil {
    width: 100%;
    height: auto;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
  }