.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px;
    gap: 20px;
  }
  
  .product-card {
    display: flex;
    flex-direction: column;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
  }
  
  .product-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .product-description {
    font-size: 14px;
    color: #666;
  }
  
  .product-price {
    font-size: 16px;
    color: #b12704;
  }

  .info-tab p {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;

  }
  
  @media only screen and (min-width: 1024px) {

    .product-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 2%;
      gap: 1%;
    }

  }