/* Estilos generales para todas las resoluciones */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(13deg, rgba(249, 163, 164, 0.9416141456582633) 1%, rgba(202, 247, 246, 0.9444152661064426) 57%);
}

.mini-navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
}

.mini-navbar span {
  font-size: 16px;
  color: #333;
}

.container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 60px;
}

.messages {
  display: flex;
  flex-direction: column;
}

.chat-message {
  display: flex;
  margin-bottom: 10px;
}

.chat-message.sent {
  justify-content: flex-end;
}

.chat-message.received {
  justify-content: flex-start;
}

.chat-message-text {
  background-color: #efefef;
  padding: 10px;
  border-radius: 20px;
  max-width: 60%;
  margin: 0 10px;
  word-wrap: break-word;
}

.chat-message.sent .chat-message-text {
  background-color: #0095f6;
  color: #fff;
  word-wrap: break-word;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.paper {
  position: fixed;
  bottom: 0;
  height: 10%;
  display: flex;
  align-items: center;
  padding: 10px;
}

.text-input {
  bottom: 0;
  width: 50%;
  border: none;
  padding: 10px;
  border-radius: 50px;
  background-color: #efefef;
  z-index: 100;
}

.button {
  bottom: 0;
  right: 0;
  background-color: #0095f6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  z-index: 100;
}

@media only screen and (min-width: 1280px) and (max-width: 1919px) and (min-height: 800px) and (max-height: 1079px) {
  .chat-container {
    height: 100vh;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .mini-navbar {
    padding: 20px;
    width: 100%;
    max-width: 1200px;
  }

  .container {
    padding: 20px;
    padding-bottom: 80px;
    width: 100%;
    max-width: 1200px;
    border-bottom: 1px solid #dbdbdb;
  }

  .chat-message {
    margin-bottom: 20px;
  }

  .chat-message-text {
    padding: 15px;
    max-width: 70%;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .paper {
    height: 15%;
    padding: 20px;
    max-width: 1200px;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
  }

  .text-input {
    padding: 15px;
    border-radius: 30px;
    width: calc(100% - 100px);
  }

  .button {
    padding: 15px 30px;
    border-radius: 30px;
  }
}

/* Media query específica para pantallas de 1920x1080 */
@media only screen and (min-width: 1920px) and (min-height: 1080px) {
  .chat-container {
    height: 100vh;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .mini-navbar {
    padding: 20px;
    width: 100%;
    max-width: 1200px;
  }

  .container {
    padding: 20px;
    padding-bottom: 80px;
    width: 100%;
    max-width: 1200px;
    border-bottom: 1px solid #dbdbdb;
  }

  .chat-message {
    margin-bottom: 20px;
  }

  .chat-message-text {
    padding: 15px;
    max-width: 70%;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .paper {
    height: 15%;
    padding: 20px;
    max-width: 1200px;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
  }

  .text-input {
    padding: 15px;
    border-radius: 30px;
    width: calc(100% - 100px);
  }

  .button {
    padding: 15px 30px;
    border-radius: 30px;
  }
}

@media only screen and (max-width: 1279px), (max-height: 799px) {
  .chat-container {
    height: 100vh;
    padding: 10px;
  }

  .mini-navbar {
    padding: 10px;
    width: 100%;
  }

  .container {
    padding: 10px;
    padding-bottom: 60px;
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
  }

  .chat-message {
    margin-bottom: 10px;
  }

  .chat-message-text {
    padding: 10px;
    max-width: 60%;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  .paper {
    height: 10%;
    padding: 10px;
    width: 85%;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
  }

  .text-input {
    padding: 10px;
    border-radius: 20px;
    width: calc(100% - 80px);
  }

  .button {
    padding: 10px 20px;
    border-radius: 20px;
  }
}