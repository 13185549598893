/* Estilos para el contenedor de las solicitudes */
.solicitudes-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Ajuste del ancho mínimo y máximo de las columnas */
  gap: 20px;
  -moz-box-shadow: 1px 1px 3px 2px #cccccc;
  -webkit-box-shadow: 1px 1px 3px 2px #cccccc;
  box-shadow: 1px 1px 3px 2px #cccccc;
}

/* Estilos para cada solicitud */
.solicitud {
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;
  width: 280px; /* Ancho ajustado para dispositivos móviles */
  display: inline-block;
}

/* Estilos para la sección de categoría */
.categoria {
  margin-bottom: 20px;
}

/* Estilos para el título de la solicitud */
.solicitud h3 {
  margin-bottom: 5px;
}

/* Estilos para los párrafos dentro de la solicitud */
.solicitud p {
  margin: 0;
  color: #555;
}

/* Estilos para el separador entre categorías */
.separator {
  margin-top: 40px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ccc;
}

/* Estilos para los detalles adicionales de la consulta */
.consultas-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Ajuste del ancho mínimo y máximo de las columnas */
  gap: 20px;
}

/* Estilos para cada consulta */
.consulta-container {
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;
  width: 280px; /* Ancho ajustado para dispositivos móviles */
  display: inline-block;
}

/* Estilos para el título de la consulta */
.consulta-header h3 {
  margin-bottom: 5px;
}

/* Estilos para los párrafos dentro de la consulta */
.consulta-header p {
  margin: 0;
  color: #555;
}

/* Estilos para los detalles adicionales de la consulta */
.consulta-details {
  display: none;
}

/* Estilos para el botón de expansión */
.expand-button {
  border: 2px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Estilos para el ícono de expansión */
.expand-icon {
  color: #007bff;
}

/* Estilos para el botón de expansión al pasar el mouse */
.expand-button:hover {
  border-color: #0056b3;
}

/* Estilos para la consulta expandida */
.consulta-container.expanded {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Efecto de brillo al pasar el mouse */
.consulta-container:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
