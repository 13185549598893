body {
    background-color: #f0f0f0;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #1cbce4;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
  }
  
  button {
    display: inline-block;
    background-color:  #f7cacb;
    color: white;
    padding: 10px 24px;
    margin: 10px 2px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
  }
  
  button:hover {
    background-color: white;
    color: black;
  }
  
  .consulta-card {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .consulta-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .consulta-card > div {
    margin-bottom: 15px;
  }
  
  .consulta-card > div:last-child {
    margin-bottom: 0;
  }