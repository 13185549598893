/* Foro.css */

.thread {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
  
  .post {
    margin-bottom: 20px;
  }
  
  .post h2 {
    margin-top: 0;
    color: #007bff;
    font-size: 1.5em;
  }
  
  .post form {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .post form label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .post form textarea,
  .post form input {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
  }
  
  .post form button {
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .post form button:hover {
    background-color: #0056b3;
  }

  .post textarea {
    max-width: 100%;
    overflow-x: auto;
  }