/* informacion.css */
.info-tab {
    text-align: center;
    padding: 20px;
}

.info-tab img {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 100px;
}

.info-tab p {
    margin: 0 auto 20px auto;
    max-width: 600px;
    line-height: 1.6;
}

.manual-button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 16px;
    background: rgb(234, 89, 110);
    background: linear-gradient(189deg, rgba(234, 89, 110, 1) 52%, rgba(244, 171, 186, 1) 98%);
    color: #fff;
    border: 4px solid #FDC1CD;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.animated-title {
    text-align: center;
    margin-bottom: 30px;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.product-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    width: 200px;
}

.product-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.product-details h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.product-details p {
    margin: 5px 0;
}