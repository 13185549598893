@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

:root {
  --background-color: #fff;
  --border-radius: 20px;
  --padding: 30px;
  --max-width: 500px;
  --max-height: 60vh;
}

.register-form-container {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  padding: var(--padding);
  border-radius: var(--border-radius);
  z-index: 2;
  width: 90%;
  max-width: var(--max-width);
  overflow-y: auto;
  max-height: var(--max-height);
}

.register-logo {
  width: 250px;
  height: 147px;
  margin-bottom: 10px;
}

.title-container {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
}

.register-label-title {
  font-size: 28px;
  color: #545454;
  text-align: center;
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  width: 48%;
}

.register-label {
  margin-bottom: 1px;
  font-size: 18px;
}

.register-rounded-input {
  margin-top: 1px;
  margin-bottom: 3px;
  padding: 10px;
  border: 1px solid #dbdbdb;
  background: #dbdbdb;
  border-radius: 25px;
  transition: border-color 0.3s;
  font-size: 16px;
  color: black;
  font-family: FontAwesome;
}

.register-rounded-input {
  border-color: #ff6b6b;
  box-shadow: 0 0 8px rgba(255, 107, 107, 0.5);
}

.register-rounded-input:focus {
  border-color: #ff6b6b;
  box-shadow: 0 0 10px rgba(255, 107, 107, 0.3);
}

.error-message {
  color: #ff6b6b;
  margin-top: 10px;
  text-align: center;
}

.register-login-button {
  flex-basis: 100%;
  padding: 12px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  margin-bottom: 10px;
  margin-left: 90px;
  margin-top: 20px;
  background: rgb(234, 89, 110);
  background: linear-gradient(117deg, rgba(234, 89, 110, 1) 52%, rgba(244, 171, 186, 1) 98%);
  color: #fff;
  border: 4px solid #FDC1CD;
}

.register-login-button:hover {
  background-color: #f7cacb;
}

.link-login {
  margin-left: 30px;
}


@media (max-width: 768px) {
  .register-container {
    padding: 20px;
  }

  .register-form-container {
    padding: 20px;
  }

  .register-logo {
    width: 180px;
    height: 150px;
    margin-bottom: 20px;
  }

  .form-group {
    width: 100%;
  }

  .register-login-button {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 344px) and (min-height: 882px) {
  .register-logo {
    width: 150px;
    height: 130px;
  }

  .register-form-container {
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 20px;
    z-index: 2;
    max-width: 500px;
    overflow-y: auto;
    max-height: 60vh;
  }

  .register-login-button {
    flex-basis: 100%;
    padding: 12px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    margin-bottom: 10px;
    margin-left: 55px;
    margin-top: 20px;
    background: rgb(234, 89, 110);
    background: linear-gradient(117deg, rgba(234, 89, 110, 1) 52%, rgba(244, 171, 186, 1) 98%);
    color: #fff;
    border: 4px solid #FDC1CD;
  }
}

@media only screen and (min-width: 1440px) {
  .register-logo {
    width: 200px;
    height: auto;
    margin-bottom: 30px;
  }
  
  .register-form-container {
    padding: 40px;
    height: 75%;
    flex-direction: column;
    align-items: left;
  }

  .register-rounded-input {
    padding: 12px;
    padding-left: 50px;
  }
  
  .login-button, .register-button {
    padding: 12px;
    font-size: 20px;
    height: 60%;
    margin-bottom: 10px;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .input-icon {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 10px;
    font-family: FontAwesome;
  }

  .placeholder-icon {
    color: #545454;
    position: absolute;
    left: 20px;
    top: 29%;
    z-index: 2;
  }

  .password-input {
    z-index: 1;
    padding-left: 40px;
  }

  .email-input {
    padding-left: 40px;
    z-index: 1;
  }

  .rounded-input {
    position: relative;
    padding: 10px;
    padding-left: 40px;
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    border-radius: 30px;
    transition: border-color 0.3s;
    font-size: 16px;
    color: black;
    margin-bottom: 15px;
  }

  .rounded-input:focus {
    border-color: #ff6b6b;
    box-shadow: 0 0 8px rgba(255, 107, 107, 0.5);
  }

  .rounded-input::placeholder {
    color: black;
    opacity: 1;
  }
}