@font-face {
  font-family: 'Aristotelica Display DemiBold';
  src: url('../fonts/Aristotelica Display DemiBold Trial.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  }

  .login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(77, 234, 225);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(189deg, rgba(77, 234, 225, 0.4962359943977591) 52%, rgba(244, 171, 186, 1) 98%);
  height: 100%;
  width: 100%;
  }
  
  .input-icon {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 10px;
  font-family: FontAwesome;
  }
  
  .placeholder-icon {
  color: #545454;
  position: absolute;
  left: 20px;
  top: 29%;
  z-index: 2;
  }
  
  .password-input {
  z-index: 1;
  padding-left: 40px; /* Ajusta según sea necesario */
  }
  
  .email-input {
  padding-left: 40px; /* Ajusta según sea necesario /
  z-index: 1; / Añade esto */
  }
  
  .input-icon .faEnvelope {
  position: absolute;
  left: auto;
  top: 1%;
  }
  
  .faEnvelope {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  }
  
  .logo {
  width: 240px;
  height: 177px;
  margin-bottom: 51px;
  }
  
  .logoLock {
  width: 210px;
  height: 195px;
  margin-bottom: 51px;
  }
  
  .login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 45px;
  height: 550px;
  border-radius: 20px;
  z-index: 2;
  }
  
  .login-cont-lock {
  padding: 50%;
  padding: 35px;
  }
  
  label {
  margin-bottom: 10px;
  font-family: 'Aristotelica Display DemiBold', sans-serif;
  color: #545454;
  }
  
  .labelEmail {
  margin-bottom: 10px;
  font-size: 28px;
  font-family: 'Aristotelica Display DemiBold', sans-serif;
  color: #545454;
  }
  
  .rounded-input {
  position: relative;
  padding: 10px;
  padding-left: 40px;
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  border-radius: 30px;
  transition: border-color 0.3s;
  font-size: 16px;
  color: black;
  margin-bottom: 15px;
  width: 100%;
  }
  
  .rounded-input {
  border-color: #ff6b6b;
  box-shadow: 0 0 8px rgba(255, 107, 107, 0.5);
  }
  
  .rounded-input::placeholder {
  color: black;
  opacity: 1;
  }
  
  .button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  }
  
  .olvidaste-password {
  margin-bottom: 20px;
  font-family: 'Aristotelica Display DemiBold', sans-serif;
  }
  
  .login-button {
  padding: 14px;
  margin: 3px;
  margin-top: 25%;
  margin-bottom: 10%;
  border-radius: 50px;
  width: 120%;
  font-size: 26px;
  border: 4px solid #FDC1CD;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  }
  
  .login-button {
  background: rgb(234, 89, 110);
  background: linear-gradient(189deg, rgba(234, 89, 110, 1) 52%, rgba(244, 171, 186, 1) 98%);
  color: #fff;
  }
  
  .login-button
  ,
  .register-button
  {
  background-color: #f7cacb;
  }
  
  .lock-button-login{
  margin-top: 190px;
  }
  
  .register-link {
  text-decoration: none;
  font-family: 'Aristotelica Display DemiBold', sans-serif;
  color: #333;
  }
  
  .input-container {
  position: relative;
  display: flex;
  align-items: center;
  }
  
  .input-logo {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  
  }
  
  /* Media query para dispositivos móviles */
  @media only screen {
  .logo {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  }
  
  .login-form {
  padding: 30px;
  margin-bottom: 10px;
  background-color: #ffffff;
  }
  
  label {
  font-size: 24px;
  margin-bottom: 8px;
  color: #333;
  }
  
  .input-logo {
  width: 20px;
  height: 20px;
  }
  
  .rounded-input {
  position: relative;
  margin-bottom: 20px;
  padding: 8px;
  padding-left: 30px;
  width: 100% !important;
  color: #000;
  text-align: center;
  border-radius: 30px;
  }
  
  .button-container {
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  }
  
  .register-button {
  width: 100%;
  margin-left: 3px;
  }
  
  .user-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .rounded-input {
  width: 100%;
  }
  
  .login-button {
  padding: 10px;
  margin-top: 20%;
  margin-bottom: 10px;
  border-radius: 40px;
  width: 100%;
  font-size: 20px;
  }
  .login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: rgb(77, 234, 225);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(189deg, rgba(77, 234, 225, 0.4962359943977591) 52%, rgba(244, 171, 186, 1) 98%);
  }
  }

  /* Media query para pantallas grandes */
@media only screen and (min-width: 1440px) {
  .logo {
    width: 200px;
    height: auto;
    margin-bottom: 30px;
  }

  .login-form {
    padding: 40px;
    height: 75%;
  }

  .labelEmail {
    font-size: 28px;
  }

  .rounded-input {
    padding: 12px;
    padding-left: 50px;
    width: 160%;
  }

  .login-button, .register-button {
    padding: 12px;
    font-size: 20px;
    height: 60%;
    margin-bottom: 10px; /* Para separar los botones uno del otro */
  }

  .button-container {
    display: flex;
    flex-direction: column; /* Para que los botones estén uno debajo del otro */
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
  }
}
  /* Media query para pantallas pequeñas */
@media only screen and (max-width: 1300px){
  .logo {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
  }

  .logoLock{
    width: 150px;
    height: auto;
    margin-bottom: 10px;
  }

  .login-form {
    padding: 35px;
    height: 70%;
  }

  label{
    margin-top: 30px;
  }

  .labelEmail {
    font-size: 28px;
  }

  .rounded-input {
    padding: 10px;
    padding-left: 45px;
    width: 160%;
  }

  .login-button, .register-button {
    padding: 12px;
    font-size: 20px;
    height: 60%;
    margin-bottom: 5px; /* Para separar los botones uno del otro */
  }

  .button-container {
    display: flex;
    flex-direction: column; /* Para que los botones estén uno debajo del otro */
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
  }
}