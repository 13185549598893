.App-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-navbar-title {
  flex-grow: 1;
  text-align: center;
}

.App-navbar-buttons {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.App-navbar-settings {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
}