/* Estilos generales */

.custom-link {
  text-decoration: none;
  color: inherit;
}

.App-title {
  font-family: 'Aristotelica Display DemiBold', sans-serif;
  font-size: 40px !important;
  color: #EA596E !important;
  font-weight: bold !important;
}

/* Estilos del panel de control */
.panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  flex-grow: 1;
}

/* Estilos del panel de control */
.App-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 90%;
  align-items: center;
}

.App-control-button {
  width: 46%;
  height: 5%;
  margin: 2%;
  margin-top: 5%;
  font-size: 20px !important;
  font-weight: bold !important;
  border-radius: 40px;
  border: none !important;
  background-color: #ffffff !important;
  color: black !important;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); /* Sombra fina al borde */
}

.consultas-button {
  width: 100%;

}

.App-control-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.App-control-button img {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  margin-top: 20%;
  margin-bottom: 8%;
}

.consultas-button img {
  width: 150px;
  height: 150px;
  margin-left: 5px;
  margin-right: 30px;
  margin-top: 2%;
}

.animated-message {
  animation: 2s ease-out 0s 1 slideInFromLeft;
  animation-fill-mode: forwards;
  top: 50px;
  left: 80%;
  margin-left: 10%;
  margin-bottom: 2%;
  transform: translateX(-50%);
  background-color: #f4abba;
  border-radius: 16px;
  padding: 1px;
  color: #662113;
  font-weight: bold;
  max-width: 81%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Aristotelica Display DemiBold', sans-serif;
}

.animated-message p {
  margin-bottom: 1%;
  margin-top: 1%;
  text-align: center;

}

/* Animación de ensamblaje para los botones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.App-control-button {
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 1024px) {

  .App-title {
    font-size: 80px;
  }

  .animated-message p {
    font-size: large;
  }

  .App-control-button img {
    width: 150px;
    height: 150px;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .consultas-button img {
    width: 100px;
    height: 100px;
    margin: 5%;
  }

}