.App-title {
  font-family: 'Aristotelica Display DemiBold', sans-serif;
  font-size: 40px !important;
  color: #EA596E !important;
  font-weight: bold !important;
}

.App-navbar-buttons {
  display: flex;
  margin-left: 5px;
  margin-right: 15px;
}

.App-navbar-button {
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 100%;
}

.App-navbar-button:last-child {
  margin-left: auto;
}

.navbar-nav {
  display: flex;
}

.nav-link {
  padding: 5px 15px;
}


/* ProfilePage.css */

.profile-container {
  max-width: 550px;
  margin: 0 auto;
  padding: 20px;
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profile-header h1 {
  font-size: 35px;
}

.profile-info {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); /* Sombra fina al borde */
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  margin-left: 16px;
}

.profile-info label {
  font-weight: bold;
}

.profile-info div {
  margin-bottom: 15px;
}

.profile-info input[type="text"],
.profile-info input[type="email"],
.profile-info input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.profile-info input[type="text"]:focus,
.profile-info input[type="email"]:focus,
.profile-info input[type="password"]:focus {
  outline: none;
  border-color: #007bff;
}

.profile-info button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-info button:hover {
  background-color: #0056b3;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  border: 5px solid #f4abba;
  -moz-box-shadow: 1px 1px 3px 2px #cccccc;
  -webkit-box-shadow: 1px 1px 3px 2px #cccccc;
  box-shadow:         1px 1px 3px 2px #cccccc;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.botonEditar {
  width: auto;
  object-fit: scale-down;
  max-width: fit-content;
  border-radius: 25px;
  border: 3px solid #f7cacb;
  cursor: pointer;
  background: rgb(234, 89, 110);
  background: linear-gradient(189deg, rgba(234, 89, 110, 1) 52%, rgba(244, 171, 186, 1) 98%);
  color: #fff;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.botonCerrarSesion {
  width: auto;
  height: 50px;
  border-radius: 25px;
  border: 3px solid #f7cacb;
  cursor: pointer;
  background: rgb(234, 89, 110);
  background: linear-gradient(189deg, rgba(234, 89, 110, 1) 52%, rgba(244, 171, 186, 1) 98%);
  color: #fff;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 2%;
  padding-bottom: 2%;
}

/* CSS para la nueva navbar */
.App-footer-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #f4abba;
  margin-left: -20px !important;
}

.point-navbar {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  background-color: #f4abba;
  border-radius: 50%;
  display: flex;
  margin-bottom: -20px;
  align-items: center;
  justify-content: center;
}

.point-navbar img {
  margin-top: -10px;
  width: 30px;
  height: 30px;
}